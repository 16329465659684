import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // Add bottom margin if element below
        "&:not(:last-child)": {
            marginBottom: "2rem",
        },
    },
    subtitle: {
        // Subtitle text generally isn't very long
        // so usually looks better to limit width.
        maxWidth: 700,
        // So we can have max-width but still
        // have alignment controlled by text-align.
        display: "inline-block",
    },
}));

function SectionHeader(props) {
    const classes = useStyles();

    const {subtitle, title, size, className, ...otherProps} = props;

    // Render nothing if no title or subtitle
    if (!title && !subtitle) {
        return null;
    }

    return (
        <Box
            component="header"
            className={
                classes.root + (props.className ? ` ${props.className}` : "")
            }
            {...otherProps}
        >
            {title && (
                <Typography
                    variant={`h${size}`}
                    gutterBottom={props.subtitle ? true : false}
                >
                    {title}
                </Typography>
            )}

            {subtitle && (
                <Typography variant="subtitle1" className={classes.subtitle}>
                    {subtitle}
                </Typography>
            )}

            {props.also && (
                <Box sx={{mt: 0, color: "rgba(0,0,0,0.6)"}}>
                    <Typography
                    >
                        Your story is safe. You can come back to it at any time

                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default SectionHeader;
