import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import {useRouter} from "next/router";

function Auth(props) {
    const router = useRouter();
    var type = props.type;


    const [formAlert, setFormAlert] = useState(null);

    const handleAuth = (user) => {
        // check if afterAuthPath is a string

        if (typeof props.afterAuthPath === "string") {
            router.push(props.afterAuthPath);
        }
        // check if afterAuthPath is a function
        if (typeof props.afterAuthPath === "function") {
            props.afterAuthPath();
        }

    };

    const handleFormAlert = (data) => {
        setFormAlert(data);
    };

    return (
        <>
            {formAlert && (
                <Box mb={3}>
                    <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                </Box>
            )}

            <AuthForm
                type={type}
                buttonAction={props.buttonAction}
                onAuth={handleAuth}
                onFormAlert={handleFormAlert}
            />

            {["signup", "signin"].includes(type) && (
                <>
                    {props.providers && props.providers.length && (
                        <>
                            <Box textAlign="center" fontSize={12} my={2}>
                                OR
                            </Box>
                            <AuthSocial
                                buttonAction={props.buttonAction}
                                providers={props.providers}
                                showLastUsed={true}
                                onAuth={handleAuth}
                                onError={(message) => {
                                    handleFormAlert({
                                        type: "error",
                                        message: message,
                                    });
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Auth;
